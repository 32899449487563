import React, { memo } from 'react';
import Layout from "../components/layout/layout";

const days = [
  {
    "name": "Maandag",
    "frenchName": "Lundi",
    "englishName": "Monday",
    "soup": "Champignonsoep",
    "frenchSoup": "Soupe champignon",
    "englishSoup": "Mushroom soup",
    "mainCourse": "Kippenpasta met gemengde groenten",
    "frenchMainCourse": "Pâtes au poulet et mix de légumes",
    "englishMainCourse": "Chicken pasta with mixed vegetables",
    "dessert": null
  },
  {
    "name": "Dinsdag",
    "frenchName": "Mardi",
    "englishName": "Tuesday",
    "soup": "Wortelsoep",
    "frenchSoup": "Soupe carotte",
    "englishSoup": "Carrot soup",
    "mainCourse": "Varkensvlees met mosterdsaus, aardappel kroketten",
    "frenchMainCourse": "Spiring de porc, sauce moutarde, croquettes de pommes de terre",
    "englishMainCourse": "Pork stir-fry, mustard sauce, potato croquettes",
    "dessert": null
  },
  {
    "name": "Woensdag",
    "frenchName": "Mercredi",
    "englishName": "Wednesday",
    "soup": "Courgettesoep",
    "frenchSoup": "Soupe courgette",
    "englishSoup": "Zucchini soup",
    "mainCourse": "Kippenfilet gevuld met champignons, champignonsaus, frietjes",
    "frenchMainCourse": "Escalope de poulet farcie aux champignons, sauce champignons, frites",
    "englishMainCourse": "Chicken escalope stuffed with mushrooms, mushroom sauce, fries",
    "dessert": null
  },
  {
    "name": "Donderdag",
    "frenchName": "Jeudi",
    "englishName": "Thursday",
    "soup": "Paprikasoep",
    "frenchSoup": "Soupe poivron",
    "englishSoup": "Pepper soup",
    "mainCourse": "Kalkoenfilet met dragon saus, rösti aardappelen",
    "frenchMainCourse": "Filet de dinde, sauce estragon, pommes rösti",
    "englishMainCourse": "Turkey fillet, tarragon sauce, rösti potatoes",
    "dessert": null
  },
  {
    "name": "Vrijdag",
    "frenchName": "Vendredi",
    "englishName": "Friday",
    "soup": "Preisoep",
    "frenchSoup": "Soupe poireaux",
    "englishSoup": "Leek soup",
    "mainCourse": "Zalm lasagne",
    "frenchMainCourse": "Lasagne de saumon",
    "englishMainCourse": "Salmon lasagna",
    "dessert": null
  },
  {
    "name": "Zaterdag",
    "frenchName": "Samedi",
    "englishName": "Saturday",
    "soup": "Groentesoep",
    "frenchSoup": "Crème de légumes",
    "englishSoup": "Vegetable cream soup",
    "mainCourse": "Gegrilde chipolata, jus van vlees, preistoemp",
    "frenchMainCourse": "Chipolata grillée, jus de viande, stoump aux poireaux",
    "englishMainCourse": "Grilled chipolata, meat juice, leek stoump",
    "dessert": null
  },
  {
    "name": "Zondag",
    "frenchName": "Dimanche",
    "englishName": "Sunday",
    "soup": "Tomatensoep",
    "frenchSoup": "Soupe tomates",
    "englishSoup": "Tomato soup",
    "mainCourse": "Geroosterde kippenbout met boerensaus, ovenaardappelen",
    "frenchMainCourse": "Cuisse de poulet au four sauce à la campagnarde, pommes de terre grenaille au four",
    "englishMainCourse": "Roasted chicken leg with country-style sauce, roasted baby potatoes",
    "dessert": null
  }
];
const alternativeMenu = {
  "dutch": "Kalfsblanquette met frietjes",
  "french": "Blanquette de veau avec frites",
  "english": "Veal blanquette with fries"
};
const Lunch = () => {
  return (
      <>
          <Layout/>
          <main className="main">
              <section className='lunch'/>
              <div className="best_price">
                  <img
                      loading='lazy'
                      src="/images/best_price_2.webp"
                      alt="Lunch"
                  />
              </div>
              <div className="top_title">
                  <h1>Lunch</h1>
              </div>

              <div className='lunch_wrap'>
                  <div className='lunch_box'>
                      <h3>10/02 - 16/02</h3>
                      <h3>11:30 – 15:00</h3>
                              {/* Альтернативное меню */}
                      {/* <div className="alternative_menu">
                          <h3 style={{color:'#ae975f'}}>Alternatief gerecht voor deze week:</h3>
                          <p style={{color:'#ae975f'}}>{alternativeMenu.dutch} - {alternativeMenu.french} - {alternativeMenu.english}</p>
                      </div>       */}
                      {days.map((day, index) => (
                          <div key={index}>
                              <h4>{day.name} – {day.frenchName} – {day.englishName}</h4>
                              <p>{day.soup} - {day.frenchSoup} - {day.englishSoup}</p>
                              <p>{day.mainCourse}</p>
                              <p>{day.frenchMainCourse}</p>
                              <p>{day.englishMainCourse}</p>
                              {day.dessert && (
                                  <p>{day.dessert} - {day.frenchDessert} - {day.englishDessert}</p>
                              )}
                          </div>
                      ))}
                  </div>
              </div>
          </main>
      </>
  );
};


export default memo(Lunch);
